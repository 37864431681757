
import RecipientsSelector from "@/components/RecipientsSelector"
import Textarea from "@evercam/shared/components/Textarea"
import { SharePermission } from "@evercam/shared/types/shares"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: { Textarea, RecipientsSelector },
  props: {
    selectedProject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emails: "",
      shareAllCameras: true,
      inviteeCamerasAccessValue: [],
      createdShare: {
        permission: "",
        emails: [],
        message: "",
      },
      isSavingShares: false,
    }
  },
  computed: {
    ...mapStores(useCameraStore),
    inviteeCamerasAccess: {
      get() {
        if (this.shareAllCameras) {
          return this.cameraExids
        } else {
          return this.inviteeCamerasAccessValue
        }
      },
      set(value) {
        this.inviteeCamerasAccessValue = [...value]
      },
    },
    rights() {
      return [
        {
          text: this.$t("content.shares.create_form.rights_labels.read_only"),
          value: SharePermission.Minimum,
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only_share"
          ),
          value: SharePermission.Share,
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.full"),
          value: SharePermission.Full,
          disabled:
            this.cameraStore.selectedCamera?.rights.indexOf("edit") === -1,
        },
      ]
    },
    cameraExids() {
      return this.selectedProject.cameras.map((camera) => camera.id)
    },
    shareDisabled() {
      return (
        !this.createdShare.emails?.length ||
        !this.createdShare.permission ||
        (!this.shareAllCameras && !this.inviteeCamerasAccessValue?.length)
      )
    },
  },
  methods: {
    async save() {
      this.$analytics.saveEvent(
        AnalyticsEvent.ProjectSettingsMembersAddMember,
        {
          members: this.createdShare.emails.join(","),
          permission: this.createdShare.permission,
          sharedCameras: this.inviteeCamerasAccessValue.join(","),
          shareAllCameras: this.shareAllCameras,
        }
      )
      const generateRightList = function (permissions) {
        let rights = ["list", "view", "snapshot"]

        if (permissions === SharePermission.Share) {
          rights = [...rights, "share"]
        } else if (permissions === SharePermission.Full) {
          rights = [...rights, "share", "edit"]
        }

        return rights.join(",")
      }
      try {
        this.isSavingShares = true
        const cameraExids = this.shareAllCameras
          ? this.cameraExids
          : this.inviteeCamerasAccessValue
        const payload = {
          ...this.createdShare,
          email: this.createdShare.emails,
          rights: generateRightList(this.createdShare.permission),
          cameraExids,
        }

        await EvercamApi.shares.shareProjectCameras(
          this.selectedProject.exid,
          payload
        )

        this.$notifications.success(
          this.$t("content.shares.share_project_cameras_success")
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.shares.share_project_cameras_failed"),
          error,
        })
      } finally {
        this.isSavingShares = false
        this.$emit("updateShares")
        this.close()
      }
    },
    close() {
      this.$emit("closeDialog")
    },
  },
}
